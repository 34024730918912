import type { Renderer } from '@fluentui/react-northstar-styles-renderer';
import type { IRenderer, IStyle, TEnhancer, TPlugin } from 'fela';
import { createRenderer } from 'fela';
import felaPluginEmbedded from 'fela-plugin-embedded';
import felaPluginFallbackValue from 'fela-plugin-fallback-value';
import felaPluginPlaceholderPrefixer from 'fela-plugin-placeholder-prefixer';
import { RendererProvider } from 'react-fela';

import { felaDisableAnimationsPlugin } from './felaDisableAnimationsPlugin';
import { felaFocusVisibleEnhancer } from './felaFocusVisibleEnhancer';
import { felaInvokeKeyframesPlugin } from './felaInvokeKeyframesPlugin';
import { felaSanitizeCssPlugin } from './felaSanitizeCssPlugin';
import sortMediaQueryMobileFirst from 'fela-sort-media-query-mobile-first';
import type { FelaRendererParam } from './types';
import { render } from 'fela-dom';

let felaDevMode = false;

try {
    // eslint-disable-next-line no-undef
    felaDevMode = !!window.localStorage.felaDevMode;
} catch {}

const blocklistedClassNames = [
    // Blocklist contains a list of classNames that are used by FontAwesome
    // https://fontawesome.com/how-to-use/on-the-web/referencing-icons/basic-use
    'fa',
    'fas',
    'far',
    'fal',
    'fab',
    // Used by https://github.com/fullcalendar/fullcalendar
    'fc',
    // .cke is used by CKEditor
    'ck',
    'cke',
];

const filterClassName = (className: string): boolean => {
    // Also ensure that class name does not contain 'ad' as it might
    // cause compatibility issues regarding Ad blockers.
    return (
        className.indexOf('ad') === -1 &&
        blocklistedClassNames.indexOf(className) === -1
    );
};

const rendererConfig = {
    devMode: felaDevMode,
    filterClassName,
    enhancers: [sortMediaQueryMobileFirst()],
    plugins: [
        felaDisableAnimationsPlugin as TPlugin,

        // is necessary to prevent accidental style typos
        // from breaking ALL the styles on the page
        felaSanitizeCssPlugin as TPlugin,

        felaPluginPlaceholderPrefixer(),
        felaInvokeKeyframesPlugin as TPlugin,
        felaPluginEmbedded(),

        // Heads up!
        // This is required after fela-plugin-prefixer to resolve the array of fallback values prefixer produces.
        felaPluginFallbackValue(),
    ],
};

// Base interface is missing types..
interface FelaRenderer extends IRenderer {
    listeners: Function[];
    nodes: Record<string, HTMLStyleElement>;
    updateSubscription: Function | undefined;
}

export interface FluentUIRenderer extends Renderer {
    reset: () => void;
}

export const createFelaRendererFactory: () => {
    create: () => FluentUIRenderer;
    felaRenderer: FelaRenderer;
} = () => {
    const felaRenderer = createRenderer(rendererConfig) as FelaRenderer;

    return {
        felaRenderer,
        create: () => {
            let usedRenderers = 0;

            const Provider = (props: any) => (
                <RendererProvider
                    renderer={felaRenderer}
                    rehydrate={typeof document !== 'undefined'}
                    targetDocument={props.target}
                >
                    {props.children}
                </RendererProvider>
            );

            return {
                registerUsage: () => {
                    usedRenderers += 1;
                },
                unregisterUsage: () => {
                    usedRenderers -= 1;

                    if (usedRenderers === 0) {
                        felaRenderer.listeners = [];
                        felaRenderer.nodes = {};
                        felaRenderer.updateSubscription = undefined;
                    }
                },

                renderFont: (font) => {
                    felaRenderer.renderFont(font.name, font.paths, font.props);
                },
                renderGlobal: (style, selector) =>
                    felaRenderer.renderStatic(style as string, selector),
                renderRule: (styles, param) => {
                    const felaParam: FelaRendererParam = {
                        ...param,
                        theme: { direction: param.direction },
                    };

                    return felaRenderer.renderRule(
                        () => styles as unknown as IStyle,
                        felaParam
                    );
                },

                // Only used by useStyles in Aria.
                getOriginalRenderer: (): IRenderer => {
                    return felaRenderer;
                },

                reset: (): void => {
                    if (felaRenderer.updateSubscription) {
                        /**
                         * Remove existing subscription
                         * @see {import('fela-dom').render}
                         */
                        felaRenderer.listeners.splice(
                            felaRenderer.listeners.indexOf(
                                felaRenderer.updateSubscription
                            ),
                            1
                        );
                        felaRenderer.updateSubscription = undefined;
                    }

                    // Base typings missing the 2nd arg
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    render(felaRenderer, document);
                },

                Provider,
            };
        },
    };
};
