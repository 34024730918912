import React from 'react';

type RenderType =
    | 'server-body'
    | 'server-head'
    | 'browser-body'
    | 'browser-head';

const RenderContext = React.createContext<RenderType | undefined>(undefined);

export const RenderContextProvider = ({
    renderType,
    children,
}: {
    renderType: RenderType;
    children: React.ReactNode;
}) => {
    return (
        <RenderContext.Provider value={renderType}>
            {children}
        </RenderContext.Provider>
    );
};

export const useRenderType = () => {
    const value = React.useContext(RenderContext);
    if (value === undefined) {
        throw new Error(
            'useRenderType must be used within a RenderContextProvider'
        );
    }

    return value;
};
